<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="name"
          :name="$tc('product', 1)"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.name"
            outlined
            :error-messages="errors[0]"
            :label="$tc('product', 1)"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="6">
        <ValidationProvider
          vid="report_type"
          :name="$t('report_type')"
          :rules="{ required: true }"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.report_type"
            item-text="state"
            item-value="abbr"
            :items="[
              { state: $tc('general', 1), abbr: 'general' },
              { state: $tc('level', 2), abbr: 'level' },
              { state: $tc('minReorder', 1), abbr: 'minReorder' },
              { state: $t('inventory'), abbr: 'inventory' }
            ]"
            :label="$t('report_type')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col v-if="form.report_type == 'level'" cols="12" lg="3" md="3" sm="6">
        <ValidationProvider
          vid="level"
          :name="$tc('level', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            v-model="form.level"
            :items="levels"
            item-text="name"
            item-value="pk"
            :label="$tc('level', 1)"
            :error-messages="errors[0]"
          ></v-select>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="
        form.report_type == 'general'
          ? $tc('general', 2)
          : form.report_type == 'level'
          ? $tc('level', 1)
          : form.report_type == 'minReorder'
          ? $tc('minReorder', 2)
          : $tc('inventory')
      "
      :headers="headers"
      :app="'inventory.movement'"
      :api="'inventory'"
      :opt="{
        name: this.form.name,
        level: this.form.level,
        report_type: this.form.report_type
      }"
      dontRemove
      dontCreate
      dontEdit
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-pdf"
          :title="$t('download', { value: $tc('inventory', 2) })"
          color="text-dark"
          @click="getInventoryPdf()"
        >
        </i-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('inventory', 2) })"
          color="text-dark"
          @click="getInventoryExcel()"
        >
        </i-btn>
      </template>
      <template v-slot:item.cost="{ item }">
        {{ item.price_type != 'matrix' ? item.cost : 'Matrix' }}
      </template>
      <template v-slot:item.priceSuggest="{ item }">
        {{ item.price_type != 'matrix' ? item.priceSuggest : 'Matrix' }}
      </template>
      <template v-slot:item.quantity="{ item }">
        <span
          :class="
            form.report_type == 'inventory' &&
            !(parseFloat(item.min_stock) < parseFloat(item.quantity))
              ? 'font-weight-black red--text my-auto'
              : 'black--text'
          "
        >
          {{ item.quantity }}
        </span>
      </template>
      <template v-slot:item.active="{ item }">
        {{ item.active ? 'Yes' : 'No' }}
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        level: '',
        report_type: 'general'
      },
      levels: []
    }
  },
  watch: {
    'form.report_type': {
      handler(val) {
        if (val == 'level') {
          this.getLevels()
        }
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('code', 2),
          value: 'internalCode'
        },
        {
          text: this.$tc('product', 1),
          value: 'name'
        },
        {
          text: this.$tc('template', 1),
          value: 'template_name'
        },
        {
          text: this.$tc('cost', 2),
          value: 'cost'
        },
        {
          text: this.$t('pricesuggest'),
          value: 'priceSuggest'
        },
        {
          text: this.form.report_type == 'minReorder' ? '' : this.$t('active'),
          value: this.form.report_type == 'minReorder' ? '' : 'active'
        },
        {
          text: this.$t(
            this.form.report_type == 'level' &&
              this.levels[0] != undefined &&
              ((this.form.level != '' &&
                this.form.level === this.levels[0].pk) ||
                this.form.level == '')
              ? this.levels[0].name
              : this.form.report_type != 'general' &&
                this.form.report_type != 'level'
              ? this.$t('minStock')
              : ''
          ),
          value:
            this.form.report_type == 'level' &&
            this.levels[0] != undefined &&
            ((this.form.level != '' && this.form.level === this.levels[0].pk) ||
              this.form.level == '')
              ? 'level_prices.0.1'
              : this.form.report_type != 'general' &&
                this.form.report_type != 'level'
              ? 'min_stock'
              : ''
        },
        {
          text: this.$t(
            this.form.report_type == 'level' &&
              this.levels[1] != undefined &&
              ((this.form.level != '' &&
                this.form.level === this.levels[1].pk) ||
                this.form.level == '')
              ? this.levels[1].name
              : this.form.report_type != 'general' &&
                this.form.report_type != 'level'
              ? this.$t('stock')
              : ''
          ),
          value:
            this.form.report_type == 'level' &&
            this.levels[1] != undefined &&
            ((this.form.level != '' && this.form.level === this.levels[1].pk) ||
              this.form.level == '')
              ? 'level_prices.1.1'
              : this.form.report_type != 'general' &&
                this.form.report_type != 'level'
              ? 'quantity'
              : ''
        },
        {
          text: this.$t(
            this.form.report_type == 'level' &&
              this.levels[2] != undefined &&
              ((this.form.level != '' &&
                this.form.level === this.levels[2].pk) ||
                this.form.level == '')
              ? this.levels[2].name
              : ''
          ),
          value:
            this.form.report_type == 'level' &&
            this.levels[2] != undefined &&
            ((this.form.level != '' && this.form.level === this.levels[2].pk) ||
              this.form.level == '')
              ? 'level_prices.2.1'
              : ''
        },
        {
          text: this.$t(
            this.form.report_type == 'level' &&
              this.levels[3] != undefined &&
              ((this.form.level != '' &&
                this.form.level === this.levels[3].pk) ||
                this.form.level == '')
              ? this.levels[3].name
              : ''
          ),
          value:
            this.form.report_type == 'level' &&
            this.levels[3] != undefined &&
            ((this.form.level != '' && this.form.level === this.levels[3].pk) ||
              this.form.level == '')
              ? 'level_prices.3.1'
              : ''
        }
      ]
    }
  },
  methods: {
    getInventoryPdf() {
      this.downloadFile({
        api: 'inventory',
        method: 'pdf',
        filename:
          this.form.report_type == 'general'
            ? this.$tc('general', 2)
            : this.form.report_type == 'level'
            ? this.$tc('level', 1)
            : this.form.report_type == 'minReorder'
            ? this.$tc('minReorder', 2)
            : this.$tc('inventory'),
        action: 'download',
        ext: 'pdf',
        opt: {
          pk: 1,
          params:
            '?name=' +
            this.form.name +
            '&level=' +
            this.form.level +
            '&report_type=' +
            this.form.report_type
        }
      })
    },
    getInventoryExcel() {
      this.downloadFile({
        api: 'inventory',
        method: 'excel',
        filename:
          this.form.report_type == 'general'
            ? this.$tc('general', 2)
            : this.form.report_type == 'level'
            ? this.$tc('level', 1)
            : this.form.report_type == 'minReorder'
            ? this.$tc('minReorder', 2)
            : this.$tc('inventory'),
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?name=' +
            this.form.name +
            '&level=' +
            this.form.level +
            '&report_type=' +
            this.form.report_type
        }
      })
    },
    async getLevels() {
      const response = await this.$api.customer.level.list({})
      this.levels = response.data.results.sort()
    },
    mounted() {
      this.getLevels()
    }
  }
}
</script>
