var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$tc('product', 1)},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"secondary--text",attrs:{"outlined":"","error-messages":errors[0],"label":_vm.$tc('product', 1)},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3","sm":"6"}},[_c('ValidationProvider',{attrs:{"vid":"report_type","name":_vm.$t('report_type'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"autocomplete":"off","outlined":"","error-messages":errors[0],"item-text":"state","item-value":"abbr","items":[
            { state: _vm.$tc('general', 1), abbr: 'general' },
            { state: _vm.$tc('level', 2), abbr: 'level' },
            { state: _vm.$tc('minReorder', 1), abbr: 'minReorder' },
            { state: _vm.$t('inventory'), abbr: 'inventory' }
          ],"label":_vm.$t('report_type')},model:{value:(_vm.form.report_type),callback:function ($$v) {_vm.$set(_vm.form, "report_type", $$v)},expression:"form.report_type"}})]}}])})],1),(_vm.form.report_type == 'level')?_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3","sm":"6"}},[_c('ValidationProvider',{attrs:{"vid":"level","name":_vm.$tc('level', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"autocomplete":"off","outlined":"","items":_vm.levels,"item-text":"name","item-value":"pk","label":_vm.$tc('level', 1),"error-messages":errors[0]},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}})]}}],null,false,2694370016)})],1):_vm._e()],1),_c('i-table',{attrs:{"title":_vm.form.report_type == 'general'
        ? _vm.$tc('general', 2)
        : _vm.form.report_type == 'level'
        ? _vm.$tc('level', 1)
        : _vm.form.report_type == 'minReorder'
        ? _vm.$tc('minReorder', 2)
        : _vm.$tc('inventory'),"headers":_vm.headers,"app":'inventory.movement',"api":'inventory',"opt":{
      name: this.form.name,
      level: this.form.level,
      report_type: this.form.report_type
    },"dontRemove":"","dontCreate":"","dontEdit":"","dontSearch":""},scopedSlots:_vm._u([{key:"extraToolbar",fn:function(){return [_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-file-pdf","title":_vm.$t('download', { value: _vm.$tc('inventory', 2) }),"color":"text-dark"},on:{"click":function($event){return _vm.getInventoryPdf()}}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-file-excel","title":_vm.$t('download', { value: _vm.$tc('inventory', 2) }),"color":"text-dark"},on:{"click":function($event){return _vm.getInventoryExcel()}}})]},proxy:true},{key:"item.cost",fn:function({ item }){return [_vm._v(" "+_vm._s(item.price_type != 'matrix' ? item.cost : 'Matrix')+" ")]}},{key:"item.priceSuggest",fn:function({ item }){return [_vm._v(" "+_vm._s(item.price_type != 'matrix' ? item.priceSuggest : 'Matrix')+" ")]}},{key:"item.quantity",fn:function({ item }){return [_c('span',{class:_vm.form.report_type == 'inventory' &&
          !(parseFloat(item.min_stock) < parseFloat(item.quantity))
            ? 'font-weight-black red--text my-auto'
            : 'black--text'},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.active",fn:function({ item }){return [_vm._v(" "+_vm._s(item.active ? 'Yes' : 'No')+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }